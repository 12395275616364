.history {
	@apply bg-green-200;
	padding: 0px 40px;
	height: 40px;
	z-index: 999 !important;

		&__body {
			padding: 26px 40px;
			overflow-y: auto;
		}
		&__right-side {
			@apply rounded-lg;
			width: calc(100% - 40px);
			margin-bottom: 25px;
		}
		&__new-enquiry {
			background: rgba(33, 150, 83, 0.05);
			color: #219653;
			padding: 4px 15px;
		}
		&__reply-recieved {
			background: rgba(49, 176, 217, 0.05);
			color: #31B0D9;
			padding: 4px 15px;
		}
		&__note {
			background: rgba(240, 165, 0, 0.08);
			color: #F0A500;
			padding: 4px 15px;
		}
		&__reminder {
			background: rgba(53, 122, 245, 0.05);
			color: #357AF5;
			padding: 4px 15px;
		}
		&__assignTo {
			background: rgba(245, 94, 94, 0.05);
			color: #F55E5E;
			padding: 4px 15px;
		}
		&__movedTo {
			background: rgba(191, 65, 235, 0.05);
			color: #BF41EB;
			padding: 4px 15px;
		}
}