.custom-checkbox {
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #005c6f;
  border-radius: 4px;
  @apply relative bg-white bg-transparent cursor-pointer;
  transition: all 300ms ease-out;
  &::after {
    content: "";
    width: 14px;
    height: 10px;
    @apply absolute opacity-0 invisible;
    background: url(/assets/icons/icon-checkbox-circle.svg) no-repeat center;
    top: 3px;
    right: 1px;
  }
  &:checked {
    @apply bg-primary;
    border: 2px solid transparent;
    &::after {
      @apply opacity-100 visible;
    }
  } 
  &--grey{
    border: 1.5px solid #908F8D;
  }
  &--sm {
    width: 18px;
    height: 18px;
    &::after {
      top: 2px;
      right: 0;
    }
  }
	&--xs {
		width: 16px;
		height: 16px;
		&::after {
			top: 1px;
			right: -1px;

			@media screen and (min-width: 1600px) {
				top: 2px;
				right: 0;
			}
		}
	}
  &--circle{
    border-radius: 50%;
    border:1.5px solid #908F8D;
  }
}
