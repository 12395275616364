.header-toggle {
	@apply flex bg-grey-300;
	width: 321px;
	height: 34px;
	border: 2px solid rgba(0, 0, 0, 0.05);
	border-radius: 20px;

	&__switch {
		@apply flex items-center cursor-pointer;
		padding: 5px 13px 5px 15px;
		width: 105px;
		height: 28px;
		border-radius: 20px;
		margin-top: 1px;

		&--active {
			@apply bg-primary;
		}
	}
}