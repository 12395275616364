.btn {
  @apply flex items-center justify-center font-semibold rounded-lg; 
  padding: 12px 18px;
  &--primary {
    @apply bg-primary text-white;
  }
  &--secondary {
    @apply bg-grey-400 text-grey-900;
  }
  &--google {
    @apply border border-grey-400 rounded-lg bg-white;
    padding: 11px 14px;
  }
  &--text {
    @apply text-primary;
    padding: 0;
    border-radius: 0;
  }
  &--grey {
    @apply text-grey-900 bg-grey-400;
  }
  &--white {
    @apply bg-white;
  }
  &--enquiry {
    @apply text-grey-900 text-sm;
    padding: 6px 37px 6px 12px;
  }
  &--md {
    padding: 10px 12px;
    font-size: 15px;

		&::placeholder {
			@apply text-grey-600;
		}
  }
  &--wide-md {
    padding: 10px 32px;
    font-size: 15px;
  }
  &--wide-15 {
    padding-left: 15px;
    padding-right: 15px; 
  }
  &--wide-20 {
    padding-left: 20px;
    padding-right: 20px; 
  }
  &--wide-md-40 {
    padding: 10px 38px;
    font-size: 15px;
  }
  &--sm {
    padding: 8px 12px;
    // font-size: 15px;
  }
  &--sm-40 {
    padding: 6px 12px;
  }
	&--sm-34 {
		padding: 7px 15px;
	}
  &--sm-60 {
    padding: 6px 25px;
  }
  &--xs {
    padding: 5px 12px;
    font-size: 14px;
  }
	&--xs-28 {
		padding: 4px 12px 4px 8px;
		max-height: 28px;
	}
	&--xs-28-p-1 {
		padding: 3px 3px 3px 8px;
		max-height: 28px;
	}
	
  &--try-again {
    @apply font-semibold;
    padding: 5px 29px;
    font-size: 14px;
    border-radius: 6px;
  }
  &--wide-sm {
    padding: 8px 16px;
  }
  &--edit-buttons {
    @apply font-semibold text-sm;
    width: 68px;
    height: 30px;
  }
  &--wide-xs {
    padding: 8px 8px;
  }
  &--facebook {
    @apply text-white;
    font-size: 15px;
    background-color: #4267b2;
  }
  &--instagram {
    @apply text-white;
    background: linear-gradient(239.62deg, #dd2a7b 7.92%, #f58529 95.65%);
  }
  &--gmail-connect {
    @apply text-white;
    background-color: #4d8ffd;
  }
  &--microsoft-connect {
    @apply text-white;
    background-color: #dc3e15;
  }
  &--file-library {
    padding: 10px 35px;
  }
  &--history {
    padding: 5px 6px;
  }
  &--copy {
    @apply absolute;
    font-size: 13px;
    padding: 6px 15px;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    &--sm {
      padding: 5px 15px;
    }
  }
	&--basic {
		@apply rounded-md text-xs flex items-center px-2 py-1 font-medium;
		border: 1px solid rgba(53, 122, 245, 0.2);
	}
  &--emoji-filter {
    @apply bg-grey-900 text-white font-normal text-13;
  }
  &--cancel {
    background: #e6e6e6;
  }
  &--default {
    @apply bg-white;
    border: 1px solid #0491ab;
    padding: 5px 13px;
    font-size: 13px;
  }
  &--outline {
    @apply bg-white border border-secondary text-primary;
  }
  &--stage {
    @apply bg-blue-100 rounded-md text-sm font-medium;
    padding: 6px 12px;
    margin-right: 6px;
  }
  &--stage-red {
    @apply rounded-md text-sm font-medium text-red-900;
    background: rgba(240, 68, 56, 0.1);
    padding: 6px 12px;
  }
  &--error, &--red {
    @apply bg-red-900 text-white;
    &:hover {
      @apply bg-red-900 text-white;
    }
  }
}
