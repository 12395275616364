.box-settings {
  &__automation-card {
    @apply border border-grey-400 bg-grey-300 border-radius-14 relative;

    &::after {
      content: "";
      background: #005c6f;
      z-index: 0;
      width: 1px;
      height: 24px;
      position: absolute;
      left: 50%;
      bottom: -25px;
    }
  }
}
