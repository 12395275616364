.enquiry-assistant-summary {
	&__body {
    height: calc(100% - 103px);
	}
	&__header {
		@apply w-full flex items-center justify-center;
		height: 50px;
		box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08), inset 0px -1px 0px #E5E5E5;
	}
	&__tabs-wrapper {
		@apply w-full h-full flex items-center border-b border-grey-400;
	}
	&__tabs-header {
    @apply relative flex items-center font-medium text-grey-700 cursor-pointer text-sm;
    &::after {
      content: "";
      @apply w-full absolute left-0 bg-primary opacity-0 invisible;
			bottom: -16px;
      height: 2px;
      transition: all 300ms;
    }
    &__active {
      @apply font-semibold text-primary;
      &::after {
        @apply opacity-100 visible;
      }
    }
    &:not(:last-child) {
      margin-right: 36px;
    }
  }
	&__section {
		@apply pt-5 relative w-1/2 m-auto;

		&--header {
			@apply text-xs font-medium text-grey-800;
			&-bottom {
				@apply text-xs font-medium text-grey-800;
				&::before {
					content: "";
					display: inline-block;
					position: absolute;
					width: 40%;
					top: 8px;
					height: 1px;
					left: 0px;
					background: #E6E4E1;
				}
				&::after {
					content: "";
					display: inline-block;
					position: absolute;
					width: 40%;
					top: 8px;
					height: 1px;
					right: 0px;
					background: #E6E4E1;
				}
			}
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 44%;
				top: 29px;
				height: 1px;
				left: 0px;
				background: #E6E4E1;
			}
			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				width: 44%;
				top: 29px;
				height: 1px;
				right: 0px;
				background: #E6E4E1;
			}
		}
		&--card {
			border: 1px solid #DDF1EC;
			border-radius: 10px;
			&-header{
				@apply w-full;
				min-height: 32px;
			}
			.notification-widget {
				border-radius: 20px;
				padding: 3px 3px 3px 7px;

				&:not(:last-child) {
					margin-right: 5px;
				}
			}
		}
	}
}