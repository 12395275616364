.list-hover {
  @apply relative p-5 mt-4 bg-white border border-grey-400 rounded-lg cursor-pointer;
  transition: border-color 300ms, box-shadow 300ms;
  &:hover,
  &.active {
    @apply border border-secondary;
    box-shadow: 0px 6px 14px -2px rgba(85, 83, 80, 0.15);
  }
  &__container {
	@apply flex;
  }
  &__avatar {
	width: 54px;
	height: 54px;
    @apply flex items-center justify-center bg-grey-300 rounded-full;
    border: 1px solid rgba(0, 0, 0, 0.05);
    > img {
      object-fit: contain;
    }
  }
}
