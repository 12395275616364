.progress-bar {
  &-container {
    @apply w-full bg-grey-400;
    height: 10px;
    border-radius: 20px;
  }
  &-slider {
    @apply w-full h-full bg-green-900;
    border-radius: 20px;
  }
}