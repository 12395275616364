.all-contacts{

  .date-container{
    
    .open-button {
      @apply text-sm text-primary font-semibold ;
      position: absolute;
      top: 35px;
      right: 11px;
      background: #fff;
      pointer-events: none;
    }
  }
  .type-list{
    @apply transition-colors ease-in-out duration-700; 
    display: flex;
    border-radius: 8px;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  .groups-list{
    @apply transition-colors ease-in-out mt-3 gap-2 duration-700; 
    display: flex;
    align-items: center;
  }
  .type-list:hover,.active{
    @apply bg-grey-90 transition-colors ease-in-out duration-700;
    .image{
      background:#FFFFFF;
    }
  }
 .image{
    background: #EAF3F6;
    border-radius: 5px;
    padding: 9px;
  }
    .contact-questions {
        @apply h-full pb-8 ;
        .question-ans-list {
          @apply bg-white border border-grey-400 mt-5;
          border-radius: 10px;
          transition: all 300ms;
          &:hover,
          &.active {
            @apply border border-secondary;
            box-shadow: 0px 6px 14px -2px rgba(85, 83, 80, 0.15);
          }
          &__title {
            font-size: 15px;
            @apply font-medium;
            &-wrapper {
              @apply flex items-center justify-between;
              padding: 15px 15px 12px 20px;
            }
          }
          &__image{
            background: #EAF3F6;
            border-radius: 5px;
            padding: 9px;
          }
          &__body {
            @apply bg-grey-300;
            padding: 15px 15px 12px 20px;
             border-bottom-left-radius: 10px;
             border-bottom-right-radius: 10px;
             .dropdown-menu{
              &__type-list{
                @apply transition-colors ease-in-out duration-700; 
                display: flex;
                border: 1px solid #E6E4E1;
                border-radius: 8px;
                align-items: center;
                padding: 8px;
                cursor: pointer;
              }
              &__type-list:hover ,.active{
                @apply border border-primary border-radius-10 transition-colors ease-in-out duration-700;
              }
              &__image{
                background: #EAF3F6;
                border-radius: 5px;
                padding: 9px;
              }
              
             }
          }
          
        }
      }
      input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
        cursor: pointer;
      }
}

