.channels__list {
	@apply flex justify-between h-10 items-center pl-4 cursor-pointer pr-4;

	&:hover{
		@apply bg-grey-90 rounded-xl;
	}

	&--toggle-slider {
		&__checkbox {
			@apply absolute opacity-0;
			width: initial;
			&:checked + label .channels__list--toggle-slider__ball {
				@apply bg-primary;
				&:before {
					left: 14px;
				}
			}
		}
		&__ball {
			@apply inline-block align-middle cursor-pointer p-0 relative;
			width: 28px;
			height: 16px;
			background: rgba(#C4C2C0, 0.6);
			border-radius: 22px;
			margin: 0 0 0 1rem;
			transition: all 0.4s ease;
			&:before {
				@apply bg-white absolute;
				content: "";
				width: 12px;
				height: 12px;
				border-radius: 50%;
				box-shadow: inset 0 1px 4px 0 rgba(221, 214, 214, 0.5), 0 0 2px 0 rgba(36, 16, 16, 0.4);
				top: 2px;
				left: 3px;
				transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			}
		}
	}
}





