.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: fit-content;
  background-color: #2b3639;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  z-index: 1;
  padding: 2px 5px;

  &__email {
    left: 40%;
    width: fit-content;
  }
  &__left {
    top: -5px;
    bottom: auto;
    right: 128%;
  }
  &__bottom {
    top: 135%;
    left: 50%;
    margin-left: -60px;
  }
  &__right {
    top: -5px;
    left: 125%;
  }
  &__top {
    bottom: 100%;
    left: 50%;
    margin-left: -50px;
  }
	&__top--new {
		bottom: 102%;
		left: -10%;
	}
	&__toggle {
		bottom: 27px;
    margin-left: 0;
    right: -25px;
	}
	&__chat-footer {
		bottom: 100%;
    left: 50%;
    margin-left: -109px;
		width: max-content;
		padding: 5px 10px;
	}
	&__account {
		bottom: 102%;
		left: 50%;
    margin-left: -65px;
		width: max-content;
		padding: 5px 10px;
	}
	&__sms-variable {
		bottom: 100%;
    left: 50%;
    margin-left: -90px;
		width: max-content;
		padding: 5px 10px;
	}
	&__left--delete-icon {
		padding: 5px 10px;
    top: -15px;
    right: 128%;
		width: 274px;
  }
	&__invisible {
		visibility: hidden !important;
		opacity: 0;
	}
}

.custom-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.custom-tooltip .tooltiptext__top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #2b3639 transparent transparent transparent;
}
.custom-tooltip .tooltiptext__chat-footer::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #2b3639 transparent transparent transparent;
}
.custom-tooltip .tooltiptext__account::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: #2b3639 transparent transparent transparent;
}
.custom-tooltip .tooltiptext__sms-variable::after {
  top: 100%;
  left: 41%;
  margin-left: -5px;
  border-color: #2b3639 transparent transparent transparent;
}
.custom-tooltip .tooltiptext__top--new::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent transparent transparent;
}
.custom-tooltip .tooltiptext__left--delete-icon::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent #2b3639;
}

.custom-tooltip .tooltiptext__left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent #2b3639;
}

.custom-tooltip .tooltiptext__bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #2b3639 transparent;
}
.custom-tooltip .tooltiptext__right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent #2b3639 transparent transparent;
}
.custom-tooltip .tooltiptext__toggle::after {
  top: 123%;
  right: 50%;
  margin-top: -5px;
  border-color: #2b3639 transparent transparent transparent;
}

.custom-tooltip:hover .tooltiptext {
  visibility: visible;
}


