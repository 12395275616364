.authentication-module {
  @apply w-full h-full flex items-center justify-center flex-col py-6;
  background: url(../../../assets/images/eb-logo-bg-shadow.svg) no-repeat left top;
  background-size: 50% 100%;
  min-height: 100vh;
  overflow: auto;
	&__container {
		width: 502px;
		@apply bg-white;
    box-shadow: 0px 8px 34px -4px rgba(85, 83, 80, 0.1);
    border-radius: 20px;
		max-height: 100%;
		padding: 45px 50px;
    .or-text {
      @apply w-full flex items-center justify-center;
      border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      > span {
        @apply relative flex items-center justify-center text-grey-700 font-bold bg-grey-200 px-5 z-10;
        height: 20px;
        font-size: 10px;
        top: 10px;
        @apply border border-grey-400;
        border-radius: 20px;
      }
    }
  }
  &__heading {
    color: #292929;
    @apply font-bold;
  }
  &.post-login {
    .authentication-module__container {
      padding: 60px 100px 80px;
    }
  }
}
