.avatar-container {
  @apply relative flex items-center justify-center flex-shrink-0 rounded-full;
  > img {
    @apply w-full h-full rounded-full;
  }
  &--full {
    > img {
      @apply object-cover;
    }
  }
  &--contain {
    > img {
      @apply object-contain;
      width: initial;
      height: initial;
      border-radius: 0;
    }
  }
  &__sup-pic {
    width: 24px !important;
    height: 24px !important;
    top: -3px;
    right: -8px;
  }
  &--bg-color {
    @apply bg-blue-800;
  }
  &__sup-icon {
    @apply flex items-center justify-center absolute bg-secondary rounded-full;
    width: 24px;
    height: 24px;
    border: 2px solid #ffffff;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
    top: -3px;
    right: -8px;
  }
  &__sup-icon--sm {
    width: 12px;
    height: 12px;
    border: 1px solid #ffffff;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
    top: 0;
    right: -3px;
  }
	&__sup-icon--xs {
		border-radius: 50%;
		top: -4px;
		right: -6px
	}
	&__sup-icon--bottom {
		border-radius: 50%;
		bottom: -3px;
		right: -5px;
	}
	&__sup-icon--bottom-0 {
		border-radius: 50%;
		bottom: 0px;
		right: 0px;
	}
  &--initials {
    width: 28px;
    height: 28px;
    color: #ffffff;
  }
}
