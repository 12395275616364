.modal {
  @apply fixed w-full h-full flex items-center justify-center top-0 left-0 right-0 bottom-0 opacity-0 invisible;
  z-index: 20;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s;

  // &--type2 {
  //   width: 73%;
  //   left: 27%;
  //   &::after {
  //     width: calc(73% - 16px) !important;
  //     height: calc(100% - 32px) !important;
  //     top: 16px !important;
  //     left: 27% !important;
  //     border-radius: 14px;
  //     background-color: rgba(0, 0, 0, 0.3) !important;
  //     box-shadow: 0px 4px 30px rgba(85, 83, 80, 0.15) !important;
  //   }
  // }
  &--type2 {
    @apply w-full h-full absolute top-0 left-0;
    &::after {
      @apply w-full h-full left-0 top-0;
      position: absolute !important;
      border-radius: 14px;
      background-color: rgba(0, 0, 0, 0.3) !important;
    }
  }
  &:after {
    content: "";
    @apply fixed w-full h-full top-0 left-0 bottom-0 right-0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    transition: all 0.3s;
  }
  &__container {
    @apply relative flex flex-col w-full h-full rounded-none bg-white z-30 overflow-y-auto opacity-0 invisible;
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s;
    max-height: 92vh;
    @screen md {
      width: 536px;
      height: initial;
      border-radius: 10px;
      // max-height: calc(100% - 40px);
    }
  }
  &.show {
    @apply opacity-100 visible;
    .modal__container {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }
  &--no-transform {
    .modal__container {
      transform: initial !important;
    }
  }
  &.open {
    @apply opacity-100 visible;
    .modal__container {
      opacity: 1;
      visibility: visible;
    }
  }
  &__header {
    @apply flex items-center justify-between px-8 py-4 bg-grey-300;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
    &--plain {
      box-shadow: none;
      background-color: transparent;
    }
		&--plain-sm {
			@apply p-4;
			box-shadow: none;
      background-color: transparent;
		}
  }
  &__body {
    @apply overflow-y-auto;
  }

  &__progress-section {
    background: #faf9f8;
    border-radius: 10px;
    padding: 23px 15px 15px;
    margin-bottom: 60px;
    margin-top: 10px;
  }
  &__uploaded-section {
    background: #faf9f8;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 60px;
    margin-top: 10px;
  }
  &__heading {
    @apply text-xl font-semibold text-grey-900;
  }
  &__small-heading {
    @apply text-lg font-semibold text-grey-900;
  }
  &__sub-heading {
    font-size: 13px;
    // @apply text-light-text;
  }
  &__close-icon {
    @apply cursor-pointer;
  }
  &__channels-icon {
    width: 60px;
    height: 60px;
  }
  &__channles-active {
    background: #eef7fc;
    border-radius: 12px;
  }
  &--image-upload {
    // display: none !important;

    @apply justify-center;
    .modal__container {
      height: 561px !important;
    }
  }
  &--image-uploaded {
    // display: none !important;

    @apply justify-center;
    .modal__container {
      height: 550px !important;
      overflow-y: hidden;
    }
  }
  &--edit {
    @apply justify-center;
    .modal__container {
      height: 592px !important;
      overflow-y: hidden;
    }
  }
  &--Error-screen {
    @apply justify-center;
    .modal__container {
      height: 395px !important;
    }
  }
  &--unsupported-screen {
    @apply justify-center;
    .modal__container {
      height: 347px !important;
    }
  }
  &__error-section {
    box-sizing: border-box;
    background: #ffffff;
    border: 2px dashed #e6e4e1;
    border-radius: 8px;
    height: 276px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 40px 85px 50px;
  }
  &__unsupported-section {
    box-sizing: border-box;
    background: #ffffff;
    border: 2px dashed #e6e4e1;
    border-radius: 8px;
    height: 228px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 34px 55px 39px;
  }
  &--product-tour {
    left: initial;
    right: 25px;
    .modal__container {
      width: 72%;
      // height: 95%;
    }
  }
  &--image-full {
    // display: none !important;
    @apply justify-center;
    left: initial;
    right: 25px;
    .modal__container {
      width: 84%;
      height: 95%;
    }
    .modal__header {
      background: #ffffff;
      box-shadow: none;
    }
  }
  &__close-full-image {
    @apply absolute right-0 top-6;
  }
  &__next-icon {
    @apply z-50 cursor-pointer;
    margin-left: 22px;
  }
  &__prev-icon {
    @apply z-50 cursor-pointer;
    margin-right: 22px;
  }
  &--extra-details {
    @apply justify-end;
    left: initial;
    right: 25px;
    .modal__container {
      width: 20%;
      height: 50%;
    }
  }
  &__search-results {
    background: #ffffff;
    box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
      0px 12px 30px -4px rgba(85, 83, 80, 0.25);
    border-radius: 10px;
    width: 93%;
    margin-top: 10px;
    padding: 8px;
    img {
      margin-right: 10px;
    }
    div {
      display: flex;
      flex-direction: row;
      padding: 8px 10px;
      align-items: center;
      border-radius: 6px;
      margin-bottom: 5px;
      justify-content: space-between;
    }
    div:not(.no-hover):hover {
      cursor: pointer;
      background-color: #eef7fc;
    }
    div:last-child {
      margin-bottom: 0px;
    }
    p {
      color: #2b3639;
    }
    .users-list {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      overflow-y: scroll;
      height: 200px;
      .item {
        padding: 1px 6px;
        width: 100%;
        justify-content: flex-start;
        img {
          width: 30px;
          margin-right: 6px;
        }
      }
      p {
        padding: 1px 0px;
        margin: 8px 6px;
      }
      .item:last-child {
        margin-bottom: 5px;
      }
    }
    .users-list::after {
      content: "";
      display: flex;
      width: 90%;
      height: 10%;
      left: 0;
      position: absolute;
      z-index: 9999999;
      margin-left: auto;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.7) 40%,
        #fff 95%
      );
    }
  }
  &__bottom-buttons {
    button {
      margin-right: 10px;
    }
    button:last-child {
      margin-right: 0px;
    }
  }
  .file-upload-wrapper {
    @apply bg-white cursor-pointer mx-auto text-center;
    padding: 60px 85px;
    border: 1.2px dashed #e6e4e1;
    border-radius: 10px;
    input[type="file"] {
      display: none;
    }
  }

  &--connect-channels {
    .modal__container {
      width: 640px;
    }
  }
  &__card {
    @apply bg-grey-300 cursor-pointer;
    border: 1px solid #e6e4e1;
    border-radius: 10px;

    &:hover {
      @apply bg-white;
      border: 1px solid #0491ab;
      border-radius: 12px;
      box-shadow: 0px 0px 13px -2px rgba(85, 83, 80, 0.1),
        0px 10px 12px -2px rgba(85, 83, 80, 0.12);
    }
  }
  &__files {
    @apply bg-white cursor-pointer;
    border: 1px solid #e6e4e1;
    border-radius: 10px;

    &:hover {
      box-shadow: 0px 6px 18px -2px rgba(85, 83, 80, 0.2);
    }
  }
  &__invite-card {
    @apply flex justify-between items-center py-2 px-4 cursor-pointer;

    &:hover {
      @apply bg-grey-90 rounded-lg;
      .modal__invite-link {
        @apply visible;
      }
    }
  }
  &__invite-link {
    @apply invisible text-primary font-semibold text-sm;
  }

  &__channel-list {
    @apply relative flex items-center px-5 py-4 bg-transparent rounded-xl cursor-pointer;
    transition: all 300ms;
    &:hover,
    &.active {
      @apply bg-grey-90;
      .btn--connect {
        @apply opacity-100 visible;
      }
    }
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    &__avatar-container {
      @apply flex items-center justify-center;
      width: 60px;
      height: 60px;
      border-radius: 14px;
      @apply bg-secondary;
      &--enquiry-bot {
        @apply bg-secondary;
      }
      &--facebook-page {
        background-color: #3880ef;
      }
      &--instagram-account {
        background: linear-gradient(239.62deg, #dd2a7b 7.92%, #f58529 95.65%);
      }
      &--web-forms {
        background-color: #6060ec;
      }
      &--email {
        background-color: #ea4335;
      }
      &--facebook-lead {
        background-color: #36447f;
      }
    }
    &__content-wrapper {
      @apply ml-5;
    }
    .btn--connect {
      @apply opacity-0 invisible;
      transition: all 300ms ease-in;
    }
  }
  &--upload-contact {
    .add-contacts-list {
      @apply relative w-1/2 p-5 border border-solid border-grey-400 rounded-xl cursor-pointer;
      transition: all 400ms ease-in-out;
      &:hover {
        box-shadow: 0px 0px 13px -2px rgba(85, 83, 80, 0.1),
          0px 10px 12px -2px rgba(85, 83, 80, 0.12);
        @apply border border-primary;
      }
      .check-icon {
        @apply absolute;
        right: 18px;
        top: 12px;
        transition: all 200ms ease-in;
      }
      &-wrapper {
        @apply flex items-center gap-5;
      }
    }
  }
  &--match-fields {
    .modal {
      &__container {
        width: 640px;
      }
      &__body.blur-bg {
        filter: blur(2px);
      }
    }
    .dropdown-menu {
      width: calc(100% - 40px);
      left: 20px;
      top: 45px;
    }
    .match-field-error-popup {
      @apply absolute w-9/12 p-5 bg-white mx-auto left-0 right-0 opacity-0 invisible z-10;
      top: 50px;
      border: 1px solid rgba(240, 68, 56, 0.5);
      box-shadow: 0px 8px 18px -2px rgba(85, 83, 80, 0.2);
      border-radius: 10px;
      &.show {
        @apply opacity-100 visible;
      }
    }
  }
}

.attach-type-list {
  @apply flex items-center cursor-pointer;
  background: #faf9f8;
  border: 1px solid #e6e4e1;
  border-radius: 10px;
  padding: 22px 25px 22px 20px;
  transition: all 300ms ease-in;
  .icon-arrow-container {
    width: 24px;
    height: 24px;
    @apply flex items-center justify-center rounded-full bg-grey-400 ml-auto;
    transition: all 300ms ease-in;
  }
  &:hover,
  &.selected {
    border: 1px solid #0491ab;
    box-shadow: 0px 0px 13px -2px rgba(85, 83, 80, 0.1),
      0px 10px 12px -2px rgba(85, 83, 80, 0.12);
    border-radius: 12px;
    background: #ffffff;
    .icon-arrow-container {
      @apply bg-primary;
      svg path {
        stroke: #fff;
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.select-files-list {
  @apply flex items-center border border-grey-400 bg-white cursor-pointer;
  border-radius: 10px;
  padding: 9px 18px;
  transition: all 300ms ease-in;
  .custom-checkbox-wrapper {
    @apply opacity-0 invisible;
    transition: all 300ms ease-in;
  }
  &:hover {
    box-shadow: 0px 6px 18px -2px rgba(85, 83, 80, 0.2);
    .custom-checkbox-wrapper {
      @apply opacity-100 visible;
    }
  }
  &:not(:last-child) {
    @apply mb-2;
  }
}

.add-custom-list {
  @apply cursor-pointer bg-white rounded-xl p-5;
  border: 1px solid #e6e4e1;

  &:hover {
    border: 1px solid #0491ab;
    box-shadow: 0px 0px 13px -2px rgba(85, 83, 80, 0.1),
      0px 10px 12px -2px rgba(85, 83, 80, 0.12);

    img {
      @apply visible;
    }
  }
}

// contacts tab modal
.modal {
  &--contact {
    @apply absolute;
    top: 61px;
    height: 93%;
    bottom: initial;
    padding-bottom: 66px;
    &::after {
      @apply absolute;
    }
    .modal__container {
      position: absolute;
      top: 50px;
      width: calc(100% - 110px);
      height: calc(100% - 110px);
      overflow-y: auto;
      padding-bottom: 5px;
    }
  }
}

.modal {
  &--CustomBox {
    .modal__container {
      overflow-y: initial;
    }
    .modal__header {
      @apply bg-grey-200;
    }
    .modal__body {
      height: 100%;
      overflow-y: initial;
    }
  }
}
