.dropdown-menu {
  @apply w-full absolute left-0 bg-white opacity-0 invisible z-10;
  top: initial;
  box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
    0px 12px 30px -4px rgba(85, 83, 80, 0.25);
  border-radius: 10px;
  transform: translateY(100px);
  transition: opacity 300ms ease-in, transform 300ms ease-in,
    visibility 300ms ease-in;
  //   overflow: hidden;
  &--with-border {
    @apply border border-grey-400;
  }
  &.no-translate {
    transform: translateY(0);
  }
  &.show {
    @apply opacity-100 visible;
    transition: opacity 300ms ease-in, transform 300ms ease-in,
    visibility 300ms ease-in;
  }
  &.open {
    @apply opacity-100 visible;
    transform: translateY(0);
  }
  &__list {
    @apply flex items-center text-sm text-grey-800 cursor-pointer;
    padding: 9px 12px;
    // border-radius: 6px;
    transition: all 300ms ease-in;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    &:hover {
      @apply text-grey-900 font-medium bg-grey-90 rounded-md; 
      transition: all 300ms ease-in;

      .dropdown-menu__list--remove-btn {
        @apply visible;
        transition: all 200ms ease-in;
      }
    }
    &.selected {
      @apply text-grey-900 font-medium;
      background: #eef7fc url(/assets/icons/icon-check.svg) no-repeat right 12px
        center;
    }
    &--remove-btn {
      @apply invisible font-semibold text-red-900;
      transition: all 200ms ease-in;
    }
  }
  &--filter {
    @apply absolute;
    left: initial;
    right: 0px;
    top: 20px;
    bottom: initial;
    width: initial;
    min-width: 216px;
    padding: 8px;

    .dropdown-menu__list {
      &:hover {
        @apply rounded-md;
      }
      &.selected {
        @apply rounded-md;
      }
    }
  }
  &--email-automation {
    @apply absolute right-0;
    bottom: 25px;
    width: initial;
    min-width: 260px;
    border-radius: 10px;
    box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
      0px 12px 30px -4px rgba(85, 83, 80, 0.25);
    padding: 15px 8px 8px;
    .dropdown-menu__list {
      padding: 6px 10px;
      &:hover {
        @apply rounded-md;
      }
      &.selected {
        @apply rounded-md;
      }
    }
    &::after {
      content: "";
      display: flex;
      width: 100%;
      height: 13%;
      left: 0;
      position: absolute;
      z-index: 9999999;
      margin-left: auto;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.7) 40%,
        #fff 95%
      );
    }
  }
  &--variables {
    @apply absolute right-0 overflow-y-auto;
    bottom: 25px;
    width: initial;
    min-width: 260px;
    border-radius: 10px;
    box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
      0px 12px 30px -4px rgba(85, 83, 80, 0.25);
    padding: 15px 8px 8px;
  }
  &__team-access {
    overflow-y: scroll;

    &::after {
      content: "";
      display: flex;
      width: 100%;
      height: 20%;
      left: 0;
      position: absolute;
      z-index: 9999999;
      margin-left: auto;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.7) 40%,
        #fff 95%
      );
    }
  }
  &--more {
    @apply absolute;
    width: initial;
    min-width: 156px;
    left: initial;
    right: 0px;
    bottom: initial;
    padding: 8px;
    top: 30px;

    .dropdown-menu__list {
      @apply font-medium;
      padding: 6px 8px;
      &:hover {
        @apply rounded-md;
      }
      &.selected {
        @apply rounded-md;
      }
    }
    .red-text {
      @apply font-medium;
      color: #f04438 !important;
    }
		&--lg {
			min-width: 180px;
		}
		&--lg-edit {
			min-width: 196px;
			top: 10px;
		}
  }
  &--edit {
    @apply absolute bg-white;
    border-radius: 10px;
    width: initial;
    min-width: 160px;
    left: initial;
    right: 0px;
    top: 30px;
    padding: 10px;

    .dropdown-menu__list {
      @apply font-medium;
      padding: 6px 8px;
      &:hover {
        @apply rounded-md;
      }
      &.selected {
        @apply rounded-md;
      }
    }
  }
  &--invite {
    @apply bg-white;
    width: 500px;
    height: 350px;
    box-shadow: 0px 4px 22px rgba(85, 83, 80, 0.1);
    border-radius: 10px;
    margin-top: 91px;
    padding: 17px 8px;

    .dropdown-menu__list {
      @apply text-grey-900;
      padding: 7px 15px 7px 10px;
      transition: all 100ms ease-in;
      &:hover {
        transition: all 100ms ease-in;
        @apply rounded-md;

        .dropdown-menu__list--right-side {
          @apply visible;
          transition: all 200ms ease-in;
        }
      }
      &.selected {
        @apply rounded-md;
      }
      &:last-child {
        margin-bottom: 20px;
      }
      &--right-side {
        @apply invisible font-semibold text-red-900;
        transition: all 200ms ease-in;
      }
    }
  }
  &--sm {
    @apply p-2 bg-white;
    box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
      0px 12px 30px -4px rgba(85, 83, 80, 0.25);
    border-radius: 10px;
    .dropdown-menu__list {
      padding: 6px 12px;
      border-radius: 6px;
      @apply bg-transparent;
      &:hover {
        @apply bg-grey-90;
      }
    }
  }
	&--reply-template {
		@apply bg-white;
		box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04), 0px 12px 30px -4px rgba(85, 83, 80, 0.25);
		border-radius: 10px;
	}
	&--snooze {
		@apply p-2;
		box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04), 0px 12px 30px -4px rgba(85, 83, 80, 0.25);
		border-radius: 10px;
		width: 240px;
		top: 32px;
		.dropdown-menu__list {
			padding: 6px 12px ;
		}
	}
  &__overflow-y-scroll {
    overflow-y: scroll;
    height: 200px;
  }
  &__overflow-y-scroll::after {
    content: "";
    display: flex;
    width: 100%;
    height: 8%;
    left: 0;
    position: absolute;
    z-index: 9999999;
    margin-left: auto;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.7) 40%,
      #fff 95%
    );
  }
  &__upload {
    bottom: initial;
    width: initial;
    min-width: 250px;
    padding: 4px;
    margin-left: 21rem;
    margin-top: 1px;
  }
}
