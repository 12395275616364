.action-box-list {
  @apply flex  px-4 py-3 bg-white border border-grey-400 cursor-pointer;
  border-radius: 12px;
  &:hover {
    @apply border-secondary;
    box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
      0px 10px 12px rgba(85, 83, 80, 0.12);
    transition: all 300ms;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &__circle {
    width: 16px;
    height: 16px;
    appearance: none;
    -webkit-appearance: none;
    border: 2px solid #636566;
    border-radius: 20px;
  }
  &__radio-btn {
    @apply opacity-0 absolute rounded;
    &:checked + .action-box-list {
      @apply border-secondary;
      box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
        0px 10px 12px rgba(85, 83, 80, 0.12);
      .action-box-list__circle {
        border: 4px solid #005c6f;
      }
    }
  }
}