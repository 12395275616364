.globel-settings {
  @apply absolute opacity-100 invisible;
  backface-visibility: hidden;
  padding: 4px 10px;
  transition: all 0.3s;
  height: 66%;
  width: 22%;
  bottom: 40px;
  left: 60px;
  background: #FFFFFF;
  box-shadow: 0px -2px 4px rgba(85, 83, 80, 0.04), 0px 12px 30px rgba(85, 83, 80, 0.25);
  border-radius: 10px;
  z-index: 999;

  &.show {
    @apply opacity-100 visible;

    .upload-body {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
      border: 1px solid red;
      width: 80%;
    }
  }


}

.card-list {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}