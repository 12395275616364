.popup {
  position: relative;
  display: inline-block;
}

.popup .tooltiptext {
  visibility: hidden;
  background-color: #0491AB;
  color: #fff;
  position: absolute;
  z-index: 9999;
  top: 150%;
  left: 50%;
  padding: 5px 10;
  margin-left: -60px;
  transition: all 300ms;
}

.popup .tooltiptext::after {
		position: absolute;
		content: "";
		bottom: 100%;
		left: 0px;
		margin: 0px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent #0491AB #0491AB;
}

.popup:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}