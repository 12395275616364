.box-card-wrapper {
  @apply flex w-full h-full overflow-auto;

  &::-webkit-scrollbar {
    display: block;
    height: 7px;
  }
	&__hide-scroll {
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
.box-card-container {
  @apply bg-grey-300 pt-3 px-4 pb-6 rounded-lg;
  min-width: 410px;
  height: fit-content;

  &__add {
    @apply bg-grey-300 pt-4 px-2 pb-6 rounded-lg;
    min-width: 410px;
    height: fit-content;
  }
}
.box-card {
  @apply flex justify-between bg-white cursor-pointer;
  width: 380px;
  border-radius: 10px;
  padding: 12px 12px 16px;
  border: 1px solid #e6e4e1;
  box-shadow: 0px 2px 5px -1px rgba(85, 83, 80, 0.08);
  &--empty {
    border: 1px solid transparent;
	box-shadow: none;
  }
}
.box-filter-assignTo {
	@apply bg-white border-radius-20 flex items-center;
	padding: 3px 10px 3px 3px;

  &:not(:last-child) {
    margin-right: 2px;
  }
}