.dashboard {
	@apply w-full h-screen bg-grey-200 overflow-hidden;
	padding: 16px 16px 16px 0;

	&__container {
		@apply h-full;
	}

	&__sidebar {
		&__expanded-section {
			width: calc(27% - 74px);
			padding: 0 35px 0 18px;

			@media screen and (min-width: 1500px) {
				width: calc(22.2% - 74px);
			}
		}

		.channels-list {
			@apply p-3 py-2 cursor-pointer;
			border-radius: 10px;

			&:hover,
			&.active {
				@apply bg-grey-100;
				transition: all 300ms;
			}

			&__icon-wrapper {
				width: 32px;
				height: 32px;
				@apply bg-white p-2;
				box-shadow: inset 0px 0px 0px 1px rgba(0, 92, 111, 0.1);
				border-radius: 100%;

				>img {
					@apply w-full h-full overflow-hidden;
				}
			}

			.p-10 {
				padding: 10px;
			}
		}

		&__nav-list {
			@apply relative flex items-center gap-3 bg-transparent cursor-pointer;
			padding-top: 9px;
			padding-bottom: 9px;
			border-radius: 10px;
			transition: all 300ms ease-in;
			z-index: 1;

			&:not(:last-child) {
				margin-bottom: 2px;
			}

			&__title {
				@apply font-medium;
				font-size: 15px;
			}

			svg path {
				fill: #4c5254;
				transition: all 300ms ease-in;
			}

			&::after {
				content: "";
				width: calc(100% + 24px);
				@apply absolute h-full bg-grey-100 top-0 opacity-0 invisible;
				left: -12px;
				border-radius: 10px;
				z-index: -1;
				transition: all 300ms ease-in;
			}

			&:hover::after,
			&.active::after {
				@apply opacity-100 visible;
			}

			&:hover svg path,
			&.active svg path {
				fill: #005c6f;
			}

			&:hover &,
			&.active & {
				&__title {
					@apply font-semibold;
				}
			}

			&--type2 {
				.dashboard__sidebar__nav-list {
					.avatar-container {
						width: 32px;
						height: 32px;
						@apply bg-white;
						box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
					}

					&.active {
						.avatar-container {
							box-shadow: inset 0px 0px 0px 1px rgba(0, 92, 111, 0.1);
						}
					}
				}
			}
		}
	}

	&__sidebar__links {
		@apply h-full flex flex-col;
		width: 74px;
		padding: 0 13px;

		&__list {
			@apply flex items-center justify-center bg-transparent cursor-pointer;
			width: 48px;
			height: 48px;
			margin-bottom: 6px;
			border-radius: 10px;
			transition: all 300ms;

			&:hover,
			&.active {
				@apply bg-grey-100;

				svg path {
					fill: #005c6f;
				}
			}

			&:hover svg path,
			&.active:hover svg path {
				fill: #005c6f;
			}

			&:hover svg rect,
			&.active:hover svg rect {
				fill: #005c6f;
			}

			&--icon-leaf {

				&:hover svg path,
				&.active:hover svg path {
					stroke: #005c6f;
					fill: unset;
				}
			}
		}
	}

	&__main {
		width: 73%;
		@apply relative flex-1 h-full ml-0 bg-white overflow-hidden;
		box-shadow: 0px 4px 30px rgba(85, 83, 80, 0.15);
		border-radius: 14px;

		&__header {
			padding: 25px 32px 25px 40px;
			@apply flex items-center justify-between border-b border-grey-400;

			.avatar-container {
				width: 52px;
				height: 52px;
				@apply relative bg-white;
				box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);

				&--bg-color {
					@apply bg-blue-800;
				}

				&--bg-grey-400 {
					@apply bg-grey-400;
				}

				&--no-box-shadow {
					box-shadow: none;
				}

				&__sup-icon {
					@apply flex items-center justify-center absolute bg-secondary;
					width: 24px;
					height: 24px;
					right: -7px;
					top: -4px;
					border: 2px solid #ffffff;
					box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
				}

				&__sup-icon--new {
					@apply flex items-center justify-center absolute;
					width: 24px;
					height: 24px;
					right: -7px;
					top: -4px;
				}
			}
		}

		&__header__full {
			@apply bg-white;
			box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08), inset 0px -1px 0px rgba(229, 229, 229, 0.8);

			&-left {
				@apply border-r border-grey-400 flex justify-between;
				width: 40%;
				padding: 12px 20px 0px 41px;
				&--assigned-to {
					@apply flex items-center;
					max-width: 130px;

					.selected-user {
						@apply mr-2 truncate;
						max-width: 90px;

						@media screen and (min-width: 1600px) {
							max-width: max-content;
						}
					}

					@media screen and (min-width: 1600px) {
						max-width: max-content;
						margin-right: 10px;
					}
				}

				&--channels-btn {
					@apply border-radius-20 flex items-center relative cursor-pointer mb-3;
					border: 1px solid rgba(230, 228, 225, 0.2);
					max-width: 140px;

					.selected__text {
						@apply text-13 font-medium ml-1 truncate mr-2;
						max-width: 80px;

						@media screen and (min-width: 1600px) {
							max-width: max-content;
						}
					}

					@media screen and (min-width: 1600px) {
						max-width: max-content;
					}
				}
			}

			&-right {
				@apply flex justify-between relative flex-1;
				padding: 10px 25px;
				&--profile-container {
					@apply flex border border-grey-400 cursor-pointer border-radius-30 py-1 pr-3 pl-1;

					&.hovered,
					&.clicked {
						@apply pl-4;
					}
				}

				&--label-container {
					@apply flex overflow-x-auto;
					max-width: 140px;

					@media screen and (min-width: 1500px) and (max-width: 1600px) {
						max-width: 250px;
					}

					@media screen and (min-width: 1601px) and (max-width:1900px) {
						max-width: 340px;
					}

					@media screen and (min-width: 1901px) {
						max-width: 400px;
					}
				}
			}
		}

		.channelbody {
			@apply flex;
			height: calc(100% - 79px);
		}

		&__body {
			@apply flex;
			height: calc(100% - 80px);

			&__filter-section {
				height: 52px;
				box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08),
					inset 0px -1px 0px rgba(229, 229, 229, 0.8);
			}

			&__section-left {
				width: 39.8%;
				padding: 20px 35px 20px 40px;
				@apply h-full border-r border-grey-400;

				@media screen and (min-width: 1500px) {
					width: calc(32% + 3px);
				}

				&.ebox {
					padding: 0px;
				}
				&__header {
					@apply bg-white;
					padding: 12px 20px 0px 41px;
					box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08), inset 0px -1px 0px rgba(229, 229, 229, 0.8);
					.assigned-to {
						@apply flex items-center;
						max-width: 130px;
	
						.selected-user {
							@apply mr-2 truncate;
							max-width: 90px;
	
							@media screen and (min-width: 1600px) {
								max-width: max-content;
							}
						}
	
						@media screen and (min-width: 1600px) {
							max-width: max-content;
							margin-right: 10px;
						}
					}

					&--channels-btn {
						@apply border-radius-20 flex items-center relative cursor-pointer mb-3;
						border: 1px solid rgba(230, 228, 225, 0.2);
						max-width: 140px;
	
						.selected__text {
							@apply text-13 font-medium ml-1 truncate mr-2;
							max-width: 80px;
	
							@media screen and (min-width: 1600px) {
								max-width: max-content;
							}
						}
	
						@media screen and (min-width: 1600px) {
							max-width: max-content;
						}
					}
				}

				&__body {
					padding: 20px 35px 20px 40px;
					height: calc(100% - 66px);
				}
			}

			&__setting-height {
				height: 108%;
			}

			&__section-right {
				@apply relative h-full flex-1;

				.type-list {
					@apply transition-colors ease-in-out duration-700;
					display: flex;
					border-radius: 8px;
					align-items: center;
					padding: 8px;
					cursor: pointer;
				}

				.groups-list {
					@apply transition-colors ease-in-out mt-3 gap-2 duration-700;
					display: flex;
					align-items: center;
				}

				.type-list:hover,
				.active {
					@apply bg-grey-90 transition-colors ease-in-out duration-700;

					.image {
						background: #FFFFFF;
					}
				}

				.image {
					background: #EAF3F6;
					border-radius: 5px;
					padding: 9px;
				}

				.card {
					&-container {
						padding: 20px 35px;
						@apply min-h-full bg-white;

						.video-container {
							@apply w-full;
							height: 335px;
						}
					}

					&-container--new {
						@apply bg-white;
						padding: 30px 35px 35px;
						border: 1px solid rgba(230, 228, 225, 0.6);
						box-shadow: 0px 4px 22px rgba(85, 83, 80, 0.1);
						border-radius: 14px;
					}

					&-container--new--design {
						@media screen and (max-width: 1500px) {
							position: absolute;
							right: 20px;
						}

						@media screen and (min-width: 1900px) {
							padding-left: 40px;
						}
					}

					&-container--mobile {
						@media screen and (min-width: 1800px) {
							width: 412px;
							padding: 3rem;
						}
					}

					&-heading {
						&.sticky {
							position: sticky;
							top: -26px;
							width: calc(100% + 70px);
							margin-left: -35px;
							padding: 18px 0 18px 35px;
							@apply bg-white;
							box-shadow: 0px 2px 4px rgba(85, 83, 80, 0.08);
							transition: all 300ms ease-in;
							// border-top-left-radius: 14px;
							// border-top-right-radius: 14px;
						}
					}
				}

				.email-provider-list {
					@apply flex items-center px-4 py-3 bg-white border border-grey-400 cursor-pointer;
					border-radius: 12px;
					top: 659px;

					/* White */

					background: #ffffff;
					/* Gray/400 */

					border: 1px solid #e6e4e1;
					border-radius: 8px;

					&__avatar {
						@apply flex items-center justify-center flex-shrink-0;
						width: 50px;
						height: 50px;
						background-color: #0491ab;
						border-radius: 12px;

						>img {
							@apply object-contain;
						}

						&--g-suite {
							background-color: #4d8ffd;
						}

						&--microsoft {
							background-color: #dc3e15;
						}

						&--email {
							background-color: #0491ab;
						}
					}

					&.active,
					&:hover {
						@apply border-secondary;
						box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
							0px 10px 12px rgba(85, 83, 80, 0.12);
						transition: all 300ms;
					}

					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}

				.account-plan-list {
					@apply flex items-center px-4 py-3 border border-grey-400 cursor-pointer;
					border-radius: 12px;
					border: 1px solid #e6e4e1;
					border-radius: 8px;

					&.active,
					&:hover {
						@apply border-secondary;
						box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
							0px 10px 12px rgba(85, 83, 80, 0.12);
						transition: all 300ms;
					}

					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}

				&.layout-1 {
					padding: 35px 3.3%;
					@apply bg-grey-200 overflow-y-auto;

					@media screen and (min-width: 1500px) {
						padding: 35px 13%;
					}
				}
			}



			&__gap {
				grid-gap: 10px;
			}

			&__user {
				@apply flex text-center;
				background: #00abba;
				width: 40px;
				height: 40px;
				border-radius: 20px;
				justify-content: center;
				padding-top: 8px;
				border: 2px solid #fff;
				color: #fff;
			}

			&__user-add {
				border: 2px solid #fff;
				position: absolute;
				left: 90px;
				border-radius: 25rem;
				width: 40px;
				height: 40px;
				text-align: center;
				padding-top: 8px;
				color: #fff;
			}
		}

		&--box-card {
			@apply bg-white border border-grey-400 rounded-xl w-full cursor-pointer;
			width: 343px;
			height: 175px;

			@media screen and (max-width: 1440px) {
				width: 310px;
			}

			&:hover {
				@apply border-secondary;
			}

			&:not(:last-child) {
				margin-right: 20px;
			}

			&__header {
				@apply text-lg font-semibold;
			}

			&__desc {
				@apply text-sm text-grey-800 mt-2;
			}
		}
	}

	&__emoji-container {
		@apply bg-white absolute;
		margin-left: -5px;
		box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
			0px 10px 12px rgba(85, 83, 80, 0.12);
		z-index: 999 !important;
		width: 520px;
		height: 324px;
		overflow-y: hidden;
		border-radius: 10px;
	}

	&__emoji-dropdown {
		@apply bg-white absolute;
		z-index: 999 !important;
		box-shadow: 0px 0px 13px rgba(85, 83, 80, 0.1),
			0px 10px 12px rgba(85, 83, 80, 0.12);
		width: 476px;
		height: calc(100% - 170px);
		overflow-y: hidden;
		border-radius: 10px;

		.dashboard__emoji-list {
			height: 100%;
			overflow-y: hidden;
		}

		.dashboard__emoji-list::after {
			content: "";
			display: none;
		}

		.dashboard__emoji-icon {
			@apply cursor-pointer;
			font-size: inherit;
		}
	}

	&__emoji-list {
		height: 170px;
		overflow-y: auto;
	}

	&__emoji-list::after {
		content: "";
		display: flex;
		width: 98%;
		height: 10%;
		left: 0;
		position: absolute;
		z-index: 9999999;
		margin-left: auto;
		bottom: 0;
		background: linear-gradient(180deg,
				rgba(255, 255, 255, 0) 0,
				rgba(255, 255, 255, 0.7) 40%,
				#fff 95%);
	}

	&__emoji-icon {
		@apply cursor-pointer;
		font-size: inherit;
		width: 22px;

		&:hover {
			background: #eaf3f6;
			border-radius: 6px;
		}
	}

	&__overflow-y-scroll {
		overflow-y: scroll;
		height: 230px;
	}

	&__overflow-y-scroll::after {
		content: "";
		display: flex;
		width: 98%;
		height: 10%;
		left: 0;
		position: absolute;
		z-index: 9999999;
		margin-left: auto;
		bottom: 0;
		background: linear-gradient(180deg,
				rgba(255, 255, 255, 0) 0,
				rgba(255, 255, 255, 0.7) 40%,
				#fff 95%);
	}
}

.sticky {
	position: sticky;
	top: 0px;
	width: 100%;
	margin-left: 0px;
	padding: 18px 0 18px 35px;
	@apply bg-white;
	box-shadow: 0px 2px 4px rgba(85, 83, 80, 0.08);
	transition: all 300ms ease-in;
	z-index: 999 !important;
	// border-top-left-radius: 14px;
	// border-top-right-radius: 14px;
}

.chat-message {
	&-container {
		@apply flex flex-col bg-white p-0;
		padding: 0;
	}

	&-header {
		padding: 12px 20px;
		background: #ffffff;
		box-shadow: 0px 4px 6px -3px rgba(85, 83, 80, 0.08),
			inset 0px -1px 0px #e5e5e5;

		&-user-heading {
			border-radius: 30px;
			height: 38px;

			&.hovered,
			&.clicked {
				@apply px-4;
				border: 1px solid #e5e5e5;
				box-shadow: 0px 3px 6px -2px rgba(85, 83, 80, 0.15);
			}
		}

		&-button {
			background: #005c6f;
			border-radius: 6px;
			padding: 2px 11px;

			&-icon {
				fill: white;
				width: 14px;
				height: 14px;
			}

			&.clicked {
				background: #eef7fc;
				color: #005c6f;

				.chat-message-header-button-icon {
					fill: #005c6f;
				}
			}
		}

		&-button:hover {
			box-shadow: 0px 5px 6px -2px rgba(85, 83, 80, 0.3);
		}
	}

	&-body {
		@apply flex-1 p-5 overflow-auto;
	}

	&-list {
		margin-bottom: 25px;

		p {
			margin-bottom: 4px;
		}

		.email-body {
			background: #ffffff;
			border-radius: 10px;
			padding: 15px;

			.item {
				padding: 11px 0px;
				border-bottom: 1px solid #e5e5e5;

				p {
					margin-bottom: -4px;
				}

				&:last-child {
					border-bottom: 0px;
				}

				&:first-child {
					padding: 0px 0px 11px;
				}

				&__attachment {
					width: 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;
					gap: 10px;

					.secondary-icon {
						width: 0px;
						visibility: hidden;

						&__visible {
							visibility: visible;
							width: 16px;
							transition: all 300ms ease-in;
						}
					}

					div {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						background: #ffffff;
						border: 1px solid #e6e4e1;
						width: 47%;
						padding: 8px 12px;
						border-radius: 8px;
					}

					div:hover {
						box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
							0px 12px 30px -4px rgba(85, 83, 80, 0.25);
					}
				}
			}
		}

		.email-body--webform {
			background: #ffffff;
			border-radius: 10px;
			padding: 20px 25px 25px;
		}

		&--sender {
			@apply flex-row-reverse text-right;

			&__email {
				background: #f4f9f9;
				border: 1px solid rgba(0, 0, 0, 0.05);
			}

			p {
				text-align: left;
			}
		}
	}

	&-footer {
		@apply mx-5 my-4;
		border: 1px solid #e6e4e1;
		border-radius: 10px;

		&__header {
			@apply flex items-center justify-between bg-grey-300 px-4 py-2;

			&__reminder {
				background: #ffffff;
			}

			.pointer {
				cursor: pointer;
			}
		}

		&__footer {
			@apply mt-5 bg-grey-200 py-2 px-6 text-xs font-medium;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		.button {
			background: #ffffff;
			border: 1px solid rgba(53, 122, 245, 0.2);
			border-radius: 6px;
			cursor: pointer;

			&__active {
				background: #ffffff;
				border: 1px solid #005c6f;
				box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
				border-radius: 6px;
			}
		}

		.button:hover {
			background: #f8fbff;
		}

		textarea {
			background: transparent;
		}

		&__reminder {
			background-color: #ebf2fe;
		}

		&__note {
			background-color: #fdf7da;

			.ps {
				background: rgba(0, 0, 0, 0.06);
				border-radius: 6px;
				font-size: 12px;
			}
		}

		.email-input {
			background: #ffffff;
			border-radius: 10px;
			padding: 15px;
			padding-bottom: 0px;

			.item {
				padding-bottom: 11px;
				border-bottom: 1px solid #e5e5e5;

				p {
					margin-bottom: -4px;
				}

				&:last-child {
					border-bottom: 0px;
				}

				&__user-name {
					white-space: pre;
					margin: 0px 2px;

					&:last-child {
						margin-right: 0px;
						padding-bottom: 0px;
					}
				}

				&__user-name:hover {
					background: #eef7fc;
					border-radius: 6px;
					padding: 4px;
				}

				input {
					@apply relative font-medium;
					color: #292929;
					margin-bottom: -4px;
					background: #ffffff;
					transition: all 300ms ease-in;
					outline: none;
				}

				&__attachment-wrapper {
					border-radius: 8px;
				}

				&__attachment {
					gap: 10px;

					.file-icon {
						width: 24px;
					}

					.attachment-item {
						border: 1px solid #e6e4e1;
						width: 47%;
						border-radius: 8px;
						font-weight: 500;

						.secondary-icon {
							width: 0px;
							visibility: hidden;

							&__visible {
								visibility: visible;
								width: 16px;
								transition: all 300ms ease-in;
							}
						}
					}

					.attachment-item:hover {
						box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
							0px 12px 30px -4px rgba(85, 83, 80, 0.25);
					}
				}
			}
		}
	}
}

.snackbar {
	@apply fixed flex items-center text-center px-3 py-2 bg-black;
	left: 74%;
	top: 10%;
	min-width: 140px;
	height: 40px;
	transform: translate(-50%, -50%);
	border-radius: 20px;
	box-shadow: 0px 8px 14px rgba(85, 83, 80, 0.25);
}

.snackbar .symbol {
	// flex: 20%;
	// margin-right: 8px;
}

.snackbar .message {
	font-size: 15px;
	@apply font-medium ml-2;
	// flex: 80%;
	// text-align: center;
}

#show {
	visibility: visible;
	animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}

#hide {
	visibility: hidden;
}

.user-name {
	white-space: pre;
	margin: 0px 2px;

	&:last-child {
		margin-right: 0px;
	}
}

.user-name:hover {
	background: #eef7fc;
	border-radius: 6px;
	padding: 4px;
}

.close-icon {
	width: 14px;
	height: 14px;
	border-radius: 100%;
	background: #005c6f;
	padding: 2px;
}

.footer-icons {
	align-self: center;
	box-shadow: 0px 0px 13px -2px rgba(85, 83, 80, 0.1),
		0px 10px 12px -2px rgba(85, 83, 80, 0.12);
	border-radius: 14px;

	.item {
		width: 102px;
		height: 70px;
		justify-content: center;

		img {
			width: 18px;
			align-self: center;
			margin-bottom: 6px;
		}

		&__label {
			visibility: hidden;
			background: #357af5;
			border-radius: 4px;
			color: #ffffff;
			padding: 2px 5px;
			top: -15px;
			position: relative;
			opacity: 0;

			&__visible {
				visibility: visible;
				opacity: 1;
				transition: all 500ms ease-in;
			}

			p {
				font-size: 9px;
				text-transform: uppercase;
			}
		}
	}

	.item:hover {
		background: #eef7fc;
		border-radius: 10px;
		transition: all 500ms ease-in;
	}
}

.bg-color-on-hover {
	@apply relative cursor-pointer;
	z-index: 1;

	&::after {
		content: "";
		width: calc(100% + 30px);
		@apply absolute h-full bg-grey-90 top-0 opacity-0 invisible;
		left: -15px;
		border-radius: 10px;
		z-index: -1;
		transition: opacity 300ms ease-in;
	}

	&--br-6 {
		&::after {
			@apply rounded-md;
		}
	}

	&--br-12 {
		&::after {
			@apply rounded-xl;
		}
	}

	&:hover::after,
	&.active::after,
	&.active {
		@apply opacity-100 visible;
	}

	&--sm {
		&::after {
			width: calc(100% + 24px);
			left: -12px;
		}

		&__left {
			&::after {
				width: calc(100% + 12px);
				left: -12px;
			}
		}
	}

	&--xs {
		&::after {
			width: calc(100% + 10px);
			left: -5px;
			border-radius: 4px;
		}
	}

	&--bg-grey-100 {
		&::after {
			@apply bg-grey-100;
		}
	}
}

.showIcon {
	&:hover {
		visibility: visible;
	}
}

.general__settings__main__header {
	padding: 14px 32px 14px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom-width: 1px;
	--tw-border-opacity: 1;
	border-color: rgba(230, 228, 225, var(--tw-border-opacity));
}