.input-field {
  @apply relative w-full font-medium;
  color: #292929;
  padding: 11px 12px;
  background: #ffffff;
  border: 1px solid #e6e4e1;
  border-radius: 8px;
  transition: all 300ms ease-in;
  outline: none;

  &--type2 {
    @apply relative w-full font-normal;
    font-size: 15px;
    border: 1.5px solid #C4C2C0;
    padding: 8px 15px;

    &::placeholder {
      @apply text-grey-600 font-normal;
      font-size: 15px;
    }

    &:focus {
      border: 1.5px solid #005c6f !important;
    }
  }

  &-label {
    @apply block text-grey-900 font-semibold;
    font-size: 15px;
    margin-bottom: 6px;
  }

  &--icon-pos-left,
  &--icon-pos-left.input-field--md {
    padding-left: 50px !important;
  }

  &--icon-user {
    background: #ffffff url("/assets/icons/icon-user.svg") no-repeat left 20px center;
  }

  &--icon-search {
    background: #fff url("/assets/icons/icon-search.svg") no-repeat left 16px center;

    &--pos-left {
      padding-left: 42px !important;
    }
  }
	&--icon-search--sm {
		background: #fff url("/assets/icons/icon-search.svg") no-repeat left 10px
		center;
		border: none;
		&:focus {
			border: none !important;
			box-shadow: none !important;
		}
	}
  &--icon-email {
    background: #ffffff url("/assets/icons/icon-email.svg") no-repeat left 20px center;
  }

  &--icon-business {
    background: #ffffff url("/assets/icons/icon-business.svg") no-repeat left
      20px center;
			&::placeholder {
				@apply font-normal text-grey-600;
			}
  }

  &--icon-web {
    background: #ffffff url("/assets/icons/icon-web.svg") no-repeat left 20px
      center;
		&::placeholder {
			@apply font-normal text-grey-600;
		}	
  }

  &--icon-industry {
    background: #ffffff url("/assets/icons/icon-industry.svg") no-repeat left 20px center;
  }

  &--icon-user-group {
    background: #ffffff url("/assets/icons/icon-user-group.svg") no-repeat left 20px center;
  }

  &--select {
    background: #fff url("/assets/icons/icon-arrow-down.svg") no-repeat right 20px center;
    padding-right: 38px;
    @apply cursor-pointer;
  }

  &--icon-date {
    background: #fff url("/assets/icons/icon-date.svg") no-repeat right 20px center;
    @apply cursor-pointer;
  }

  .input-field--icon-lock {
    background: #fff url("/assets/icons/icon-lock.svg") no-repeat left 10px
  }

  &--select--sm {
    @apply cursor-pointer;
    background: #fff url("/assets/icons/icon-arrow-down.svg") no-repeat right 10px center;
    padding-right: 12px;
  }

  &--icon-lock {
    background: #fff url("/assets/icons/icon-lock.svg") no-repeat left 20px center;
  }

  &--select.input-field--icon-user-group {
    background: url("/assets/icons/icon-user-group.svg") no-repeat left 20px center,
      url("/assets/icons/icon-arrow-down.svg") no-repeat right 20px center;
  }

  &--select.input-field--icon-industry {
    background: url("/assets/icons/icon-industry.svg") no-repeat left 20px center,
      url("/assets/icons/icon-arrow-down.svg") no-repeat right 20px center;
  }

  &:focus {
    border: 1px solid #005c6f;
    box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
  }

  &--sm {
    padding: 6px 12px;
    @apply text-sm;

    &::placeholder {
      @apply text-sm text-grey-600;
    }
  }

  &--sm-38 {
    padding: 8px 12px;
    @apply text-sm;

    &::placeholder {
      @apply text-sm text-grey-600;
    }
  }
	&--md-34 {
		padding: 7px 15px;
		@apply text-sm;

		&::placeholder {
			@apply text-sm text-grey-600;
		}
	}
  &--md-38 {
    padding: 7px 12px;
    @apply text-base-1;

    &::placeholder {
      @apply text-base-1 text-grey-600;
    }
  }

  &--md {
    padding: 10px 12px;
    @apply text-sm;

    &::placeholder {
      @apply text-sm text-grey-600;
    }
  }

  &--xs {
    padding: 5px 12px;
  }

  &--xs-32 {
    padding: 4px 12px;
  }

  &--lg {
    padding: 10px 325px 10px 16px;
  }


  &--lg-15-20 {
    padding: 15px 20px;
  }

  &--edit {
    padding: 5px 10px;
    width: 430px;
  }

  &__timer-input {
    width: 106px;
    height: 42px;
    border: 1.5px solid #C4C2C0;

    &:focus {
      border: 1.5px solid #005c6f !important;
    }
  }

  &__noborder {
    border: none;
    padding: 0px;
    border-radius: 0px;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  &--add-reminder {
    @apply text-sm font-normal;
    border: none;
    background: #EBF2FE;
    border-radius: 10px;
    padding: 15px;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }


	&--lg-15-20 {
		padding: 15px 20px;
	}
	&--lg-12-15 {
		padding: 12px 15px;

		&::placeholder {
			@apply text-sm font-normal text-grey-600;
		}
	}
	&--edit {
		padding: 5px 10px;
		width: 430px;
	}
	&__timer-input {
		width: 106px;
		height: 42px;
		border: 1.5px solid #C4C2C0;

		&:focus {
			border: 1.5px solid #005c6f !important;
		}
	}
	&__no-focus {
		@apply px-8 pt-6 pb-10;
		border: none;
		border-bottom: 1px solid #E6E4E1;
		&:focus {
			border: none;
			border-bottom: 1px solid #E6E4E1;
			box-shadow: none;
		}
	}
	&__noborder {
		border: none;
		padding: 0px;
		border-radius: 0px;

		&:focus {
			border: none;
			box-shadow: none;
		}
	}
	&--add-reminder {
		@apply text-sm font-normal;
		border: none;
		background: #EBF2FE;
		border-radius: 10px;
		padding: 15px;

		&:focus {
			border: none;
			box-shadow: none;
		}
	}

  &__search-results {
    @apply absolute bg-white;
    width: 555px;
    padding: 16px 0px;
    z-index: 99 !important;
    border: 1px solid #e6e4e1;
    box-shadow: 0px -2px 4px -2px rgba(85, 83, 80, 0.04),
      0px 12px 30px -4px rgba(85, 83, 80, 0.25);
    border-radius: 10px;

    span {
      font-size: 13px;
    }

    div {
      @apply flex items-center;
      flex-direction: row;
      padding: 10px 0px;
      border-radius: 6px;
      margin-bottom: 2px;

      p {
        font-size: 15px;
      }

      p:not(.no-hover):hover {
        color: #2b3639;
        font-size: 15px;
        font-weight: 500;
      }
    }

    div:not(.no-hover):hover {
      cursor: pointer;
      background-color: #eef7fc;

      p {
        color: #2b3639;
        font-size: 15px;
        font-weight: 500;
      }
    }

    div:first-child {
      margin-top: 5px;
    }

    &__textarea {
      padding: 9px 15px;
    }
  }

  &.error {
    border: 1px solid #eb5757;

    &:focus {
      border: 1px solid #eb5757;
    }
  }

  &--prepend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .close-icon {
      background-color: #005c6f;
      border-radius: 50%;
      padding: 4px;
      width: 16px;
      height: 16px;
    }

    input {
      flex: 1;
      outline: none;
    }

    &--focus {
      border: 1px solid #005c6f;
      box-shadow: 0px 0px 0px 1.5px rgba(0, 92, 111, 0.25);
    }
  }

  &__border {
    border: 1.5px solid #C4C2C0;
  }
}

.custom-select {
  @apply relative w-full truncate cursor-pointer;
  font-size: 15px;
  padding: 12px;
  padding-right: 45px;
  background: #f1f6fa;
  border: 1px solid #dbe3ea;
  border-radius: 10px;
  transition: all 300ms ease-in;
  outline: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;

  // background: url("/assets/images/icon-arrow-down.svg") no-repeat right 14px
  //     center;
  &:focus {
    @apply bg-white;
    border: 1px solid #595fed;
    box-shadow: 0px 4px 4px #dee3e8;
    border-radius: 10px;
    mix-blend-mode: normal;
  }

  &--search {
    // background: #f1f6fa url("/assets/images/icon-search.svg") no-repeat right
    //     10px center;
    padding-right: 40px;
  }

  &--sm {
    padding: 8px 12px;
    padding-right: 45px;
  }

  &.error {
    border: 1px solid #eb5757;
  }

  &--no-border {
    width: initial;
    @apply text-right;
    font-size: 14px;
    padding: 0;
    padding-right: 20px;
    box-shadow: none;
    background-size: 12px;
    border: 0;
    border-radius: 0;
    background-position: right;

    &:focus {
      @apply bg-white;
      border: 0;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &__automation {
    @apply py-2 px-4 whitespace-nowrap overflow-ellipsis relative rounded-lg cursor-pointer;
    background: url('../../../assets/icons/icon-caret-down.svg') right 10px center no-repeat;
    border: 1.5px solid #C4C2C0;
    -webkit-appearance: none;
    width: 106px;
    height: 42px;
  }

  &--nudges {
    @apply rounded-lg;
    background-color: #ffffff;
    background: url('../../../assets/icons/icon-caret-down.svg') right 10px center no-repeat;
    border: 1.5px solid #C4C2C0;
    width: 124px;
    height: 32px;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    padding-left: 15px;
  }
}

.icon-eye-toggle {
  @apply absolute cursor-pointer;
  right: 22px;
  top: 17px;
  height: 15px;
}

.icon-chevron-down {
  transform: matrix(1, 0, 0, -1, 0, 0);
}