.tabs {
  &-wrapper {
    @apply w-full flex items-center border-b border-grey-400;
    &--contact {
      padding: 20px 40px 0;
      .tabs {
        &-header {
        }
      }
    }
  }
  &-header {
    font-size: 13px;
    @apply relative flex items-center font-medium text-grey-700 pb-3 cursor-pointer;
    &::after {
      content: "";
      @apply w-full absolute left-0 bottom-0 bg-primary opacity-0 invisible;
      height: 2px;
      transition: all 300ms;
    }
    &__active {
      @apply font-semibold text-primary;
      &::after {
        @apply opacity-100 visible;
      }
    }
    &:not(:last-child) {
      margin-right: 36px;
    }
  }
}
